.footer {
    background-color: #000;
}
.footer .container {
    padding-top: 80px;
    padding-bottom: 20px;
}
.footer-title {
    font-size: 55px;
    color: #fff;
    text-align: center;
    font-weight: 700;
}
.footer-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    padding: 40px 0;
    height: 300px;
}

.footer h5 {
    color: #fff;
    text-align: center;
    font-size: 20px;
}

.footer-item a {
    color: #fff;
    font-size: 35px;
    transition-duration: var(--transition-duration);
}

.footer-item a:hover {
    background-color: #fff;
    color: #000;
    box-shadow: 4px 4px 0 #ff9c9c;
}

.cat {
    padding-top: 30px;
    display: flex;
    justify-content: end;
    overflow: hidden;
    height: 62px;
}

.cat a {
    height: 64px;
}

.cat img {
    padding-right: 20px;
    transition-duration: var(--transition-duration)
}

.cat img:hover {
    filter: invert(1);
}

.footer small {
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    padding-top: 10px;
}

.footer small a {
    text-decoration: none;
    color: #ff9c9c;
    margin-left: 6px;
    font-weight: 600;
    transition-duration: var(--transition-duration);
}

.footer small a:hover {
    background-color: #fff;
    color: #000;
    box-shadow: 2px 2px 0 #ff9c9c;
}

@media (max-width: 768px) {
    .footer-title {
        font-size: 30px;
    }
    .footer-item a {
        font-size: 25px;
    }
}