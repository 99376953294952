.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.img.loading {
    width: 100%;
    height: 100%;
    opacity: 0;
    object-fit: cover;
    background-color: #f5f5f5;
}

.img.loaded {
    opacity: 0;
    animation: loaded 0.5s ease-in-out forwards;
}

@keyframes loaded {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}