.section .title {
    height: 400px;
    font-size: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    letter-spacing: 4px;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    cursor: pointer;
    transition-duration: var(--transition-duration);
    user-select: none;
}

.section .title a {
    text-decoration: none;
    color: #000;
}

.section .title:hover a {
    color: #fff;
}

.section .title:hover {
    background-color: #000;
    color: #fff;
}

.content {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 80px 0;
    position: relative;
    z-index: 1;
}

.content .item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 2px solid #000;
    padding-bottom: 16px;
    color: #000;
    transition-duration: var(--transition-duration);
    text-decoration: none;
}

.content .item-title {
    font-size: 30px;
    font-weight: 600;
}

.content .item-content {
    font-size: 20px;
    font-weight: 400;
    
}

.content .item-hover {
    cursor: pointer;
    display: flex;
    transition-duration: var(--transition-duration);
    box-shadow: 0px 4px 0 var(--color-custom);
}

.content .item-hover:hover {
    background-color: #000;
    color: #fff;
    box-shadow: 10px 10px 0 var(--color-custom);
}

.content .item-hover:active {
    box-shadow: 5px 5px 0 var(--color-custom);
}

.content .item a {
    color: #000;
    text-decoration: none;
}

.content .item-hover:hover .item-title,
.content .item-hover:hover a {
    color: #fff;
}

@media (max-width: 768px) {
    .section .title {
        font-size: 30px;
        height: 200px;
    }
    .content .item-title {
        font-size: 18px;
    }
    .content .item-content {
        font-size: 16px;
    }
    .content {
        padding: 100px 0;
    }
}