:root {
  --color-main: #ffffff;
  --color-secondary: #f6f7f8;
  --color-tertiary: #cccccc;
  --color-primary: #FBEC5D;
  --color-border: #2E2E3B;

  --text-light: #ffffff;
  --text-dark: #2E2E3B;
  --box-shadow: 0 10px 20px rgb(0 0 0 / 20%);
  --box-shadow-sm: 0 10px 20px rgb(0 0 0 / 10%);
  
  --transition-duration: .15s;
}

* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

img {
  user-select: none;
}

*::-webkit-scrollbar {
  background-color: #fff;
  width: 4px;
}
*::-webkit-scrollbar-thumb {
  background-color: #060606;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #000;
}

/* @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap'); */

html {
  overflow-x: hidden;
  /* scroll-behavior: smooth; */
}
body {
  /* background-size: 40px 40px;
  background-image: radial-gradient(circle, #000000de 1px, rgba(0, 0, 0, 0) 1px); */
  margin: 0;
  font-family: monospace;
  /* font-family: Rubik,sans-serif; */
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  min-height: 100vh;
  background-color: #fff;
  overflow-x: hidden;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

.blink {
  animation: blink 1s linear infinite;
}

.flex {
  display: flex;
}

.opacity-0 {
  opacity: 0;
}

.bg-1 {
  background-color: #FBEC5D;
}

.bg-2 {
  background-color: #93ff9c;
}

.bg-3 {
  background-color: #ff9c9c;
}

.cat-img img {
  outline: 2px solid #000;
  width: 100%;
}

.cat-img img {
  transition-duration: var(--transition-duration);
  cursor: 'pointer';
}

.cat-img img:hover {
  box-shadow: 16px 16px 0 #000!important;
}

.cat-img {
  position: relative;
  gap: 150px!important;
  padding: 200px 0!important;
}

.cat-img-container {
  position: relative;
  margin: 0 10%;
}

.meow {
  position: absolute;
  width: 150px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  outline: 2px solid #000;
  left: -20px;
  top: -20px;
  user-select: none;
  z-index: 2;
}

.meow span {
  background-color: transparent;
  color: #000;
  font-size: 25px;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0 20px;
  }
}