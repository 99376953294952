.nav {
    background-color: #fff;
}

.nav .container {
    display: flex;
    justify-content: space-around;
    gap: 16px;
    padding: 16px 0;
}

.nav-item a {
    text-decoration: none;
    color: #000;
    background-color: #fff;
    font-size: 20px;
}

.nav-item::after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: #000;
    transition: width .3s;
}

.nav-item.active::after,
.nav-item:hover::after {
    width: 100%;
    transition: width .3s;
}

@media (max-width: 768px) {
    .nav-item a {
        font-size: 14px;
    }
}