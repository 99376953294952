.about {
    padding:  80px 0;
    border-top: 2px solid #000;
}

.about strong {
    font-size: 24px;
}

.about .image img {
    width: 50%;
    height: 50%;
    object-fit: fill;
    object-position: center;
    outline: 4px solid #000;
    box-shadow: 8px 8px 0 #000;
    cursor: pointer;
    min-height: 250px;
    transition-duration: var(--transition-duration);
}
.about .image img:hover {
    box-shadow: 12px 12px 0 #ff9c9c;
}
.about .image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about .text {
    padding-top: 40px;
    display: flex;
    justify-content: center;
}

.about .text span {
    font-size: 20px;
    text-align: center;
    max-width: 500px;
    background-color: #fff;
    align-items: center;
    width: fit-content;
}

@media (max-width: 768px) {
    .about {
        padding: 50px 0;
    }
    .about .image img {
        width: 90%;
    }
}