header {
    border-bottom: 2px solid #000;
    padding: 50px 0;
}

.name {
    font-size: 54px;
    text-align: center;
    padding-bottom: 15px;
    text-decoration: none;
}

.name span:active {
    box-shadow: 6px 6px 0 #ff9c9c;
}

.name.active span:hover {
    box-shadow: 6px 6px 0 #ff9c9c;
}

.name.active  span {
    transition-duration: var(--transition-duration);
    background: #000;
    color: #fff;
}

.name a {
    text-decoration: none;
}

.name span {
    font-weight: 600;
    color: #000;
    background-color: #fff;
    cursor: pointer;
    position: relative;
    transition-duration: var(--transition-duration);
}

.name span:hover {
    color: #fff;
    background-color: #000;
}

.description {
    text-align: center;
}

.description span {
    font-size: 24px;
    color: #000;
    background-color: #fff;
    opacity: 0.8;
    padding-bottom: 40px;
}

@media (max-width: 768px) {
    .name {
        font-size: 30px;
    }

    .description span {
        font-size: 18px;
    }
}